/* ------------------ INVESTIGATION FLEXBOX ------------------ */
.container-fluid {
  clear: both;
}

.container-fluid form .row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.container-fluid form .row .col {
  flex-basis: 30%;
  margin-right: 2.5%;
}

.container-fluid form .row .col.legacy {
  flex-basis: 30%;
}

.container-fluid form .row .col:first-child {
  margin-left: 0;
}

.container-fluid form .row .col:not(.legacy).col-1 { flex-basis: 1.25%; }
.container-fluid form .row .col:not(.legacy).col-2 { flex-basis: 2.5%; }
.container-fluid form .row .col:not(.legacy).col-3 { flex-basis: 3.75%; }
.container-fluid form .row .col:not(.legacy).col-4 { flex-basis: 5%; }
.container-fluid form .row .col:not(.legacy).col-5 { flex-basis: 6.25%; }
.container-fluid form .row .col:not(.legacy).col-6 { flex-basis: 7.5%; }
.container-fluid form .row .col:not(.legacy).col-7 { flex-basis: 8.75%; }
.container-fluid form .row .col:not(.legacy).col-8 { flex-basis: 10%; }
.container-fluid form .row .col:not(.legacy).col-9 { flex-basis: 11.25%; }
.container-fluid form .row .col:not(.legacy).col-10 { flex-basis: 12.5%; }
.container-fluid form .row .col:not(.legacy).col-11 { flex-basis: 13.75%; }
.container-fluid form .row .col:not(.legacy).col-12 { flex-basis: 15%; }
.container-fluid form .row .col:not(.legacy).col-13 { flex-basis: 16.25%; }
.container-fluid form .row .col:not(.legacy).col-14 { flex-basis: 17.5%; }
.container-fluid form .row .col:not(.legacy).col-15 { flex-basis: 18.75%; }
.container-fluid form .row .col:not(.legacy).col-16 { flex-basis: 20%; }
.container-fluid form .row .col:not(.legacy).col-17 { flex-basis: 21.25%; }
.container-fluid form .row .col:not(.legacy).col-18 { flex-basis: 22.5%; }
.container-fluid form .row .col:not(.legacy).col-19 { flex-basis: 23.75%; }
.container-fluid form .row .col:not(.legacy).col-20 { flex-basis: 25%; }
.container-fluid form .row .col:not(.legacy).col-21 { flex-basis: 26.25%; }
.container-fluid form .row .col:not(.legacy).col-22 { flex-basis: 27.5%; }
.container-fluid form .row .col:not(.legacy).col-23 { flex-basis: 28.75%; }
.container-fluid form .row .col:not(.legacy).col-24 { flex-basis: 30%; }
.container-fluid form .row .col:not(.legacy).col-25 { flex-basis: 31.25%; }
.container-fluid form .row .col:not(.legacy).col-26 { flex-basis: 32.5%; }
.container-fluid form .row .col:not(.legacy).col-27 { flex-basis: 33.75%; }
.container-fluid form .row .col:not(.legacy).col-28 { flex-basis: 35%; }
.container-fluid form .row .col:not(.legacy).col-29 { flex-basis: 36.25%; }
.container-fluid form .row .col:not(.legacy).col-30 { flex-basis: 37.5%; }
.container-fluid form .row .col:not(.legacy).col-31 { flex-basis: 38.75%; }
.container-fluid form .row .col:not(.legacy).col-32 { flex-basis: 40%; }
.container-fluid form .row .col:not(.legacy).col-33 { flex-basis: 41.25%; }
.container-fluid form .row .col:not(.legacy).col-34 { flex-basis: 42.5%; }
.container-fluid form .row .col:not(.legacy).col-35 { flex-basis: 43.75%; }
.container-fluid form .row .col:not(.legacy).col-36 { flex-basis: 45%; }
.container-fluid form .row .col:not(.legacy).col-37 { flex-basis: 46.25%; }
.container-fluid form .row .col:not(.legacy).col-38 { flex-basis: 47.5%; }
.container-fluid form .row .col:not(.legacy).col-39 { flex-basis: 48.75%; }
.container-fluid form .row .col:not(.legacy).col-40 { flex-basis: 50%; }
.container-fluid form .row .col:not(.legacy).col-41 { flex-basis: 51.25%; }
.container-fluid form .row .col:not(.legacy).col-42 { flex-basis: 52.5%; }
.container-fluid form .row .col:not(.legacy).col-43 { flex-basis: 53.75%; }
.container-fluid form .row .col:not(.legacy).col-44 { flex-basis: 55%; }
.container-fluid form .row .col:not(.legacy).col-45 { flex-basis: 56.25%; }
.container-fluid form .row .col:not(.legacy).col-46 { flex-basis: 57.5%; }
.container-fluid form .row .col:not(.legacy).col-47 { flex-basis: 58.75%; }
.container-fluid form .row .col:not(.legacy).col-48 { flex-basis: 60%; }
.container-fluid form .row .col:not(.legacy).col-49 { flex-basis: 61.25%; }
.container-fluid form .row .col:not(.legacy).col-50 { flex-basis: 62.5%; }
.container-fluid form .row .col:not(.legacy).col-51 { flex-basis: 63.75%; }
.container-fluid form .row .col:not(.legacy).col-52 { flex-basis: 65%; }
.container-fluid form .row .col:not(.legacy).col-53 { flex-basis: 66.25%; }
.container-fluid form .row .col:not(.legacy).col-54 { flex-basis: 67.5%; }
.container-fluid form .row .col:not(.legacy).col-55 { flex-basis: 68.75%; }
.container-fluid form .row .col:not(.legacy).col-56 { flex-basis: 70%; }
.container-fluid form .row .col:not(.legacy).col-57 { flex-basis: 71.25%; }
.container-fluid form .row .col:not(.legacy).col-58 { flex-basis: 72.5%; }
.container-fluid form .row .col:not(.legacy).col-59 { flex-basis: 73.75%; }
.container-fluid form .row .col:not(.legacy).col-60 { flex-basis: 75%; }
.container-fluid form .row .col:not(.legacy).col-61 { flex-basis: 76.25%; }
.container-fluid form .row .col:not(.legacy).col-62 { flex-basis: 77.5%; }
.container-fluid form .row .col:not(.legacy).col-63 { flex-basis: 78.75%; }
.container-fluid form .row .col:not(.legacy).col-64 { flex-basis: 80%; }
.container-fluid form .row .col:not(.legacy).col-65 { flex-basis: 81.25%; }
.container-fluid form .row .col:not(.legacy).col-66 { flex-basis: 82.5%; }
.container-fluid form .row .col:not(.legacy).col-67 { flex-basis: 83.75%; }
.container-fluid form .row .col:not(.legacy).col-68 { flex-basis: 85%; }
.container-fluid form .row .col:not(.legacy).col-69 { flex-basis: 86.25%; }
.container-fluid form .row .col:not(.legacy).col-70 { flex-basis: 87.5%; }
.container-fluid form .row .col:not(.legacy).col-71 { flex-basis: 88.75%; }
.container-fluid form .row .col:not(.legacy).col-72 { flex-basis: 90%; }
.container-fluid form .row .col:not(.legacy).col-73 { flex-basis: 91.25%; }
.container-fluid form .row .col:not(.legacy).col-74 { flex-basis: 92.5%; }
.container-fluid form .row .col:not(.legacy).col-75 { flex-basis: 93.75%; }
.container-fluid form .row .col:not(.legacy).col-76 { flex-basis: 95%; }
.container-fluid form .row .col:not(.legacy).col-77 { flex-basis: 96.25%; }
.container-fluid form .row .col:not(.legacy).col-78 { flex-basis: 97.5%; }
.container-fluid form .row .col:not(.legacy).col-79 { flex-basis: 98.75%; }
.container-fluid form .row .col:not(.legacy).col-80 { flex-basis: 100%; }
