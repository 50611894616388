@font-face {
    font-family: OptumSansRegular;
    src: url(../../assets/fonts/OptumSans-Regular.otf)
}

.logo {
    padding-left: 5%;
    padding-top: 5px;
}
.logo-title {
    display: flex;
}

.d-flex {
    display: flex!important;
}

.app-title {
    padding-top: 6.1%;
    font-size: 16px;
    font-family: "OptumSansRegular", Arial, sans-serif;
    color: white;
}

.optum-logo {
    max-height: 50px;
    max-width: 138px;
    padding-top: 15px;
    padding-right: 10px ;
    height: 100%;
    width: 100%;
}

.footer {
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: #fff;
    z-index: 1000;
}

.local {
    background-color: #316BBE;
}

.dev {
    background-color: #a62626;
}

.qa {
    background-color: #43277c;
}

.stg {
    background-color: #008272;
}

.prd {
    background-color: #e5710b;
}

.environment-label {
    font-weight: 600;
    opacity: 0.8;
    color:white;
    font-size: 32px;
    font-family: "OptumSansRegular", Arial, sans-serif;
}

.date-time{
    padding-top: 25px;
    padding-left: 1%;
    padding-right: 9%;
    opacity: 0.8;
    color:white;
    font-size: 12.8px;
    font-family: "Frutiger", Arial, sans-serif;
}

button {
    color: white;
    background: none !important;
    padding: 0 !important;
    cursor: pointer;
    font-size: 18px;
    font-family: "OptumSansRegular", Arial, sans-serif;
}

.button-divider {
    border-left: 1px solid white;
    height: 20%;
}

.section-divider-left {
    padding-left:4%;
    border-left: 1px solid white;
    height: 20%;
}

.section-divider-right {
    padding-right:15px;
    border-left: 1px solid white;
    height: 14px;
}

.button-divider-logout {
    border-left: 1px solid white;
    padding-right:10px !important;
    padding-left: 10px !important;
    font-size: 2.4em;
}

.helpLogin{
    margin-left: auto;
    padding-top: 17px;
    text-align: left;
    font-size: 16px;
    font-family: "OptumSansRegular", Arial, sans-serif;
}

.logout {
    padding-right:20px !important;
    font-size: 16px;
    font-family: "OptumSansRegular", Arial, sans-serif;
}
.user {
    padding-right:20px;
    font-size: 10%;
}

.help {
    padding-right:20px;
    padding-left: 20px !important;
    font-size: 10%;
}

.footer--global {
    background-color: #fff;
    padding: 30px 15px;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.container .legal-bar {
    font-size: .875em;
    text-align: left;
    padding-top: 20px;
    border-top: 1px solid #e0e0e0;
    margin-top: 30px;
}

.env {
    padding-top: 11px;
    padding-bottom: 12px;
}
