/* enter your customisation css in this file, it is loaded so it will overrive the default css*/
@font-face {
    font-family: frutigerBold;
    src: url(../fonts/frutiger-bold.ttf)
}

@font-face {
    font-family: frutigerLight;
    src: url(../fonts/frutiger-light.ttf)
}

@font-face {
    font-family: frutigerRoman;
    src: url(../fonts/frutiger-roman.ttf)
}

@font-face {
    font-family: OptumSansRegular;
    src: url(../../assets/fonts/OptumSans-Regular.otf)
}

@font-face {
    font-family: OptumSansBold;
    src: url(../fonts/OptumSans-Bold.otf)
}

.app-title-header {
    padding-top: 10px;
}

/* toolbar changes */
#toolbar {
    position: relative !important;
    border-image-slice: 1;
    padding: 0 0px !important;
    background: #ffffff;        
    height: 37px !important;
}

#toolbar .function-keys {
    overflow-x: visible;
    padding-bottom: 5px !important;
}

#toolbar .function-keys .p-megamenu .p-megamenu-root-list>li>a {
    text-align: center;
    margin-left: 16px;
}

#toolbar .function-keys .p-megamenu .p-megamenu-root-list>li>a span {
    color: #fff!important;
    white-space: nowrap;
}

#toolbar:not(.popup-keys){
    top: 0;
}
/* end of toolbar changes */

.display-val {
    padding: 7px;
    font-size: 14px!important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    color: #848484;
    background: none;
}

/* p-button changes */
/* Display calendar icons */  
.p-button.calendar-toggle-btn:enabled:hover,
.p-button.calendar-toggle-btn:enabled:focus {
  color: #005597;
}

/* gear button style */
.f-grid .p-button.p-button-secondary.p-button-gear {
    margin: 10px 0px;
    border: none;
    color: #316BBE;
}
  
.p-button:enabled:active {
  color: #012e51;
}

/* today button - calendar */
.p-button.p-button-text {
    color: white;
    padding: 2px!important;
}

/* clear button - calendar */
.p-button.p-button-secondary.p-button-text, .p-buttonset.p-button-secondary > .p-button.p-button-text, .p-splitbutton.p-button-secondary > .p-button.p-button-text{
    color: white;
    padding: 2px!important;
}

button.p-button.p-component.legacy-action-button {
    padding: 3px !important;
}

button.p-button.p-component.legacy-action-button:active {
    padding: 3px !important;
}

/* end of p-button changes */

/*
//////////////////////// COMMENTED OUT F-GRID BUTTONS TO ALLOW VISIBILITY
//////////////////////// REMOVE WHEN GEAR BUTTON IN TABLE IMPLEMENTED
*/
.p-button {
    background-color: #316BBE !important; 
} 
.f-grid .p-datatable-header .action-buttons .actions button {
    border-color:#316BBE;
    background-color: #316BBE !important;
    border-width: 1px !important;
    padding: 3px !important;
    letter-spacing: 1px;
    color: #FFFFFF;
    font-family: "OptumSansRegular";
    padding-top: -2px !important;
    transition: all .1s ease;
    -webkit-transition: all .1s ease;
}
.f-grid .p-datatable-header .action-buttons .actions button:active {
    border-color:#316BBE;
    background-color: #316BBE !important;
    border-width: 1px !important;
    padding: 3px !important;
    letter-spacing: 1px;
    color: #FFFFFF;
    font-family: OptumSansBold,Arial,sans-serif!important;
    padding-top: -2px !important;
    transition: all .1s ease;
    -webkit-transition: all .1s ease;
}

.f-grid .p-datatable-header .action-buttons .actions button:hover {
    border-color:#00396C;
    background-color: #00396C !important;
    color: #FFFFFF;
    box-shadow: 2px #00396C !important;
    padding-top: 0px !important;
    transition: all .1s ease;
    -webkit-transition: all .1s ease;
}


.content-input label {
    font-family: OptumSansRegular,Arial,sans-serif!important;
    color: #222;
    display: inline-block;
    width: 100%;
    font-size: 12px !important;
    pointer-events: none!important;
    margin: 0 0 7px;
}

.content-input label:not(.orphan-label) {
    font-family: OptumSansBold,Arial,sans-serif!important;
    color: #222;
    font-size: 14px !important;
    font-weight: bold;
    padding: 7px;
}
.content-input.label-on-top>label:not(.orphan-label){
    font-family: OptumSansBold,Arial,sans-serif!important;
    color: #222;
    font-weight: bold;
    font-size: 12px !important;
    padding: 7px;
}

.content-input.label-on-top {
    font-family: OptumSansBold,Arial,sans-serif!important;
    color: #222;
    font-size: 12px !important;
    font-weight: 700;
}
.content-input.legacy label {
    font-family: OptumSansBold,Arial,sans-serif!important;
    color: #222;
    font-size: 12px !important;
    width: 38%;
    padding:7px;

}

.content-input.pnk-color .orphan-label, .content-input.pnk-color .p-inputtext {    
    color: #222 !important;
    font-size: 12px;
    font-family: OptumSansRegular,Arial,sans-serif!important;
}

.content-input input.p-inputtext {
    border-radius: 4px !important;
    font-size: 14px;
    font-weight: 400 !important;
    border-width: 1px;
    border-color: #E0E0E0;
    font-family: OptumSansRegular,Arial,sans-serif!important;
	
}

.display-val div {
	width: max-content;
}

/*.content-input.legacy{
	width: max-content;
}*/

.p-dropdown {
    border-color: #E0E0E0 !important;
    
}


/* fix for dropdowns displaying in tables */
.f-grid .p-datatable-tbody .p-dropdown, .p-datatable-tbody .p-inputtext:disabled {
    border: none !important;
    background: inherit !important;
    background-color: inherit !important;
}
.f-grid .p-dropdown .p-inputtext {
    background: none !important;
    font-family: OptumSansRegular,Arial,sans-serif!important;
	font-size: 14px !important;
    text-align: center;
}

textarea.p-inputtext {
    border-color: #E0E0E0 !important;
}

.container-fluid form .row {
    background-color: #f5f5f5 !important;
    padding-top: 15px !important; 
    padding-left: 10px;
}

.p-dialog .p-dialog-content .container-fluid form .row {
    display: grid;
    grid-template-columns: repeat(99, auto) 2em;
}

.p-dialog .p-dialog-content .container-fluid form .row1 {
    display: grid;
}

.wrkApsAddtlPatPayCSS{
  display: grid;
  width: 36em;
  grid-template-columns: repeat(99, auto) 2em;
}

.aptStepsNbrCSS{
  display: grid;
  width: 9em;
  grid-template-columns: repeat(99, auto) 2em;
}

.p-dialog .p-dialog-content .container-fluid form .row1 .col.col-101{
      margin-right: 3px;
      margin-left: auto;
      padding: 1px;
      background-color: black;
      color: white;
      font-family: OptumSansBold,Arial,sans-serif!important;
}

.container-fluid form .row1 {
    background-color: #f5f5f5 !important;
    padding-top: 15px !important; 
    padding-left: 10px;
}


.f-grid .p-datatable .p-datatable-thead>tr>th {
    font-size: 14px;
}

body.app-root .p-dialog .p-dialog-header span, body.app-root .p-dialog .p-dialog-titlebar span {
    text-transform: inherit;
}

.content-input.legacy label {
    font-family: OptumSansBold,Arial,sans-serif!important;
    font-weight: bold;
    width: auto;
}

.p-dialog .p-dialog-content {
    overflow-y: visible;
    padding-top: 0px !important;
}

.main{
    margin-bottom: 50px;
}

 /*hover on grid row */
.f-grid .p-datatable .p-datatable-wrapper .p-datatable-tbody > tr.p-highlight {
    background-color: #fbf8d0;
    border-color: #ffd952;
    border-style: solid;
    border-width: 1px;
}
.f-grid .p-datatable .p-datatable-wrapper .p-datatable-tbody > tr.p-highlight > td {
    border: none;
    border-left: 1px solid #c8c8c8;
}
.f-grid .p-datatable .p-datatable-wrapper .p-datatable-tbody > tr:hover {
    background-color: #ccebf6 !important;
    border-color: #7ccfe9 !important;
    border-style: solid;
    border-width: 1px;
}
.f-grid .p-datatable .p-datatable-wrapper .p-datatable-tbody > tr:hover > td {
    border: none;
    border-left: 1px solid #c8c8c8;
}
body .f-grid .p-datatable .p-datatable-tbody>tr>td {
    padding: 2px 4px;
}
body .f-grid .p-datatable .p-datatable-tbody>tr>tdp-selection-column{
    padding: 12px 12px;
}

/* paginator */
.f-grid .p-datatable .p-paginator {
    display:block;
    height:40px;
}
.p-paginator .p-dropdown {
    float:right;
    width:80px!important;
	text-align: center;
    padding-top: 4px;
}
.p-paginator .p-paginator-current{
    float:right;
}
.p-paginator-page, .p-paginator-next, .p-paginator-prev {
    float:left;
}

.p-menu-list .p-submenu-header{
    background-color: white;
}

.material-icons span {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}
.p-button.p-button-gear.material-icons{
    background: none !important;
}

.p-dialog {
    overflow: scroll;
}

.dialog-header {
    float: left;
    padding-right:20px;
}

.close-button {
    float: right;
    padding-right:20px;
}

.snapshot-close-button {
    float: right;
    padding-right:7px;
}
.snapshot-close-button button {
    border: none;
} 

.help-button {
    color: #00396C !important;
    float: right;
    padding-right:8px;
    margin-top:0px !important;
    font-size: 12px !important;
    font-weight: normal !important;
    font-family: OptumSansRegular, Arial, sans-serif !important
}

.help-button:hover {
    color: #00396C !important;
    cursor: pointer; 
    float: right;
    padding-right:8px;
    margin-top:0px !important;
    font-size: 12px !important;
    font-weight: normal !important;
    font-family: OptumSansRegular, Arial, sans-serif !important
}

.help-button:active {
    color: #00396C !important;
    cursor: default; 
    float: right;
    padding-right:8px;
    margin-top:0px !important;
    font-size: 12px !important;
    font-weight: normal !important;
    font-family: OptumSansRegular, Arial, sans-serif !important
}

#mdiv {
    width: 25px;
    height: 25px;
  }

.mdiv {
    height: 25px;
    width: 2px;
    margin-left: 12px;
    background-color: #316BBE;
    transform: rotate(45deg);
    Z-index: 1;
}

.md {
    height: 25px;
    width: 2px;
    background-color: #316BBE;
    transform: rotate(90deg);
    Z-index: 2;
  }
  
  @media (min-width: 1200px) {
    .content-container {
        /* top right bottom left */
        margin: 0 10vw 0 10vw;

    }
    .global-header{
        padding-left: 10vw;
    }
    .p-dialog-content .content-container{
        margin: 0;
    }
  }

a {
    color: #316BBE;
}

a:hover {
    cursor: pointer; 
    color: #00396C;
    text-decoration: underline;
}

a:active {
    cursor: default; 
    color: #316BBE;
    text-decoration: none;
}

.p-sortable-column-icon {
    position:relative;
    top:-20px;
    right:0;
    float: right;
    height: 0;
}

.f-grid .p-datatable .p-datatable-thead>tr>th.p-sortable-column .header-title {
    margin: 3px 25px 3px 0;
}

.f-grid .p-datatable .p-datatable-thead>tr>th .header-title {
    text-align: left!important;
    font-family: OptumSansRegular,Arial,sans-serif!important;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: black;
}

.content-input.blu-color .orphan-label,.content-input.blu-color .p-inputtext {
    font-family: OptumSansBold,Arial,sans-serif!important;
    color: #222 !important;
    font-size: 12px !important;
}

.display-val strong {
    font-family: OptumSansBold,Arial,sans-serif!important;
    color: #222 !important;
    font-size: 14px !important;    
}

.p-megamenu-horizontal .p-megamenu-root-list{
    flex-wrap: nowrap;
}

.content-input.trq-color .orphan-label {
    color: #222 !important;
    font-size: 26px!important;
    font-family: OptumSansRegular,Arial,sans-serif!important;
}

.content-input.wht-color .orphan-label {
    color: #222 !important;
    font-size: 18px!important;
    font-family: OptumSansBold,Arial,sans-serif!important;
    
}

.f-grid .p-datatable-wrapper .p-dropdown input.p-dropdown-label {
    min-width: 50px!important;
    text-align: left;
}

/* end or work arounds */

.p-dialog{
    overflow: auto;
}

.selection-box {
    width: calc(100% + 2px);
    position: relative;
    left: -1px;
    padding: 8px 1px; 
    cursor: pointer;  
}

.icon-label {
    display: flex;
    justify-content: space-between;
}

.action-label {
    padding-top: 4px;
    padding-left: 5px;
    font-size: 16px;
}

h1 {
    text-transform: none;
}
/* sizing for non pop-up items */
.container-fluid form .row .col.col-1 { flex-basis: 7em; } 
.container-fluid form .row .col.col-2 { flex-basis: 7.5em; }
.container-fluid form .row .col.col-3 { flex-basis: 8em }
.container-fluid form .row .col.col-4 { flex-basis: 8.5em; }
.container-fluid form .row .col.col-5 { flex-basis: 9.5em; }
.container-fluid form .row .col.col-6 { flex-basis: 10em;}
.container-fluid form .row .col.col-7 { flex-basis: 11em; }
.container-fluid form .row .col.col-8 { flex-basis: 11.5em; }
.container-fluid form .row .col.col-9 { flex-basis: 12.5em; }
.container-fluid form .row .col.col-10 { flex-basis: 13em; }
.container-fluid form .row .col.col-11 { flex-basis: 13.5em; }
.container-fluid form .row .col.col-12 { flex-basis: 14.5em; }
.container-fluid form .row .col.col-13 { flex-basis: 15em; }
.container-fluid form .row .col.col-14 { flex-basis: 15.5em; }
.container-fluid form .row .col.col-15 { flex-basis: 16.5em; }
.container-fluid form .row .col.col-16 { flex-basis: 17em; }
.container-fluid form .row .col.col-17 { flex-basis: 18em; }
.container-fluid form .row .col.col-18 { flex-basis: 18.5em; }
.container-fluid form .row .col.col-19 { flex-basis: 19.5em; }
.container-fluid form .row .col.col-20 { flex-basis: 20em; }
.container-fluid form .row .col.col-21 { flex-basis: 20.5em; }
.container-fluid form .row .col.col-22 { flex-basis: 21.5em; }
.container-fluid form .row .col.col-23 { flex-basis: 22em; }
.container-fluid form .row .col.col-24 { flex-basis: 23em; }
.container-fluid form .row .col.col-25 { flex-basis: 23.5em; }
.container-fluid form .row .col.col-26 { flex-basis: 24em; }
.container-fluid form .row .col.col-27 { flex-basis: 25em; }
.container-fluid form .row .col.col-28 { flex-basis: 25.5em; }
.container-fluid form .row .col.col-29 { flex-basis: 26.5em; }
.container-fluid form .row .col.col-30 { flex-basis: 27em; }
.container-fluid form .row .col.col-31 { flex-basis: 28em; }
.container-fluid form .row .col.col-32 { flex-basis: 28.5em; }
.container-fluid form .row .col.col-33 { flex-basis: 29em; }
.container-fluid form .row .col.col-34 { flex-basis: 30em; }
.container-fluid form .row .col.col-35 { flex-basis: 30.5em; }
.container-fluid form .row .col.col-36 { flex-basis: 31.5em; }
.container-fluid form .row .col.col-37 { flex-basis: 32em; }
.container-fluid form .row .col.col-38 { flex-basis: 33em; }
.container-fluid form .row .col.col-39 { flex-basis: 33.5em; }
.container-fluid form .row .col.col-40 { flex-basis: 34em; }
.container-fluid form .row .col.col-41 { flex-basis: 35em; }
.container-fluid form .row .col.col-42 { flex-basis: 35.5em; }
.container-fluid form .row .col.col-43 { flex-basis: 36.5em; }
.container-fluid form .row .col.col-44 { flex-basis: 37em; }
.container-fluid form .row .col.col-45 { flex-basis: 37.5em; }
.container-fluid form .row .col.col-46 { flex-basis: 38.5em; }
.container-fluid form .row .col.col-47 { flex-basis: 39em; }
.container-fluid form .row .col.col-48 { flex-basis: 40em; }
.container-fluid form .row .col.col-49 { flex-basis: 40.5em; }
.container-fluid form .row .col.col-50 { flex-basis: 41em; }
.container-fluid form .row .col.col-51 { flex-basis: 42em; }
.container-fluid form .row .col.col-52 { flex-basis: 42.5em; }
.container-fluid form .row .col.col-53 { flex-basis: 43.5em; }
.container-fluid form .row .col.col-54 { flex-basis: 44em; }
.container-fluid form .row .col.col-55 { flex-basis: 45em; }
.container-fluid form .row .col.col-56 { flex-basis: 45.5em; }
.container-fluid form .row .col.col-57 { flex-basis: 46em; }
.container-fluid form .row .col.col-58 { flex-basis: 47em; }
.container-fluid form .row .col.col-59 { flex-basis: 47.5em; }
.container-fluid form .row .col.col-60 { flex-basis: 48.5em; }
.container-fluid form .row .col.col-61 { flex-basis: 49em; }
.container-fluid form .row .col.col-62 { flex-basis: 49.5em; }
.container-fluid form .row .col.col-63 { flex-basis: 50.5em; }
.container-fluid form .row .col.col-64 { flex-basis: 51em; }
.container-fluid form .row .col.col-65 { flex-basis: 52em; }
.container-fluid form .row .col.col-66 { flex-basis: 52.5em; }
.container-fluid form .row .col.col-67 { flex-basis: 53.5em; }
.container-fluid form .row .col.col-68 { flex-basis: 54em; }
.container-fluid form .row .col.col-69 { flex-basis: 54.5em; }
.container-fluid form .row .col.col-70 { flex-basis: 55.5em; }
.container-fluid form .row .col.col-71 { flex-basis: 56em; }
.container-fluid form .row .col.col-72 { flex-basis: 57em; }
.container-fluid form .row .col.col-73 { flex-basis: 57.5em; }
.container-fluid form .row .col.col-74 { flex-basis: 58em; }
.container-fluid form .row .col.col-75 { flex-basis: 59em; }
.container-fluid form .row .col.col-76 { flex-basis: 59.5em; }
.container-fluid form .row .col.col-77 { flex-basis: 60.5em; }
.container-fluid form .row .col.col-78 { flex-basis: 61em; }
.container-fluid form .row .col.col-79 { flex-basis: 61.5em; }
.container-fluid form .row .col.col-80 { flex-basis: 62.5em; }

.content-input {
    margin-right: 1%;
}
/* fix for icons not aligning correctly when labelLocation set to above */
.content-input.legacy .promptable-btn {
    top: auto;
}

.content-input.legacy .calendar-toggle-btn {
    top: auto;
}

.f-grid .f-text .p-inputtext, .f-grid .selection-box .p-inputtext,.f-grid .f-numeric .p-inputtext {
    font-family: OptumSansRegular,Arial,sans-serif!important;
    color: #222 !important;
    font-size: 12px !important;   
    font-weight: 400 !important;      
}

.content-input.blu-color .orphan-label,.content-input.blu-color .p-inputtext {
    font-family: OptumSansRegular,Arial,sans-serif!important;
    color: #106EBE !important;
    font-size: 12px !important;
}

/* sizing for pop-up items */
.p-dialog .p-dialog-content .container-fluid form .row .col.col-1 { width: 7em; } 
.p-dialog .p-dialog-content .container-fluid form .row .col.col-2 { width: 7.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-3 { width: 8em }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-4 { width: 8.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-5 { width: 9.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-6 { width: 10em;}
.p-dialog .p-dialog-content .container-fluid form .row .col.col-7 { width: 11em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-8 { width: 11.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-9 { width: 12.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-10 { width: 13em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-11 { width: 13.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-12 { width: 14.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-13 { width: 15em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-14 { width: 15.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-15 { width: 16.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-16 { width: 17em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-17 { width: 18em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-18 { width: 18.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-19 { width: 19.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-20 { width: 20em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-21 { width: 20.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-22 { width: 21.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-23 { width: 22em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-24 { width: 23em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-25 { width: 23.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-26 { width: 24em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-27 { width: 25em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-28 { width: 25.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-29 { width: 26.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-30 { width: 27em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-31 { width: 28em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-32 { width: 28.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-33 { width: 29em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-34 { width: 30em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-35 { width: 30.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-36 { width: 31.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-37 { width: 32em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-38 { width: 33em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-39 { width: 33.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-40 { width: 34em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-41 { width: 35em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-42 { width: 35.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-43 { width: 36.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-44 { width: 37em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-45 { width: 37.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-46 { width: 38.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-47 { width: 39em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-48 { width: 40em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-49 { width: 40.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-50 { width: 41em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-51 { width: 42em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-52 { width: 42.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-53 { width: 43.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-54 { width: 44em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-55 { width: 45em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-56 { width: 45.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-57 { width: 46em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-58 { width: 47em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-59 { width: 47.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-60 { width: 48.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-61 { width: 49em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-62 { width: 49.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-63 { width: 50.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-64 { width: 51em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-65 { width: 52em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-66 { width: 52.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-67 { width: 53.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-68 { width: 54em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-69 { width: 54.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-70 { width: 55.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-71 { width: 56em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-72 { width: 57em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-73 { width: 57.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-74 { width: 58em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-75 { width: 59em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-76 { width: 59.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-77 { width: 60.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-78 { width: 61em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-79 { width: 61.5em; }
.p-dialog .p-dialog-content .container-fluid form .row .col.col-80 { width: 62.5em; }

/* this will be replaced by addClass */
/*For rcsnh004 field*/
.container-fluid form .row .col.col-128 { 
    flex-basis: 2%;
    margin-right: 3px;
    margin-left: auto;
    padding: 1px;
    background-color: black;
    color: white;
    font-family: OptumSansBold,Arial,sans-serif!important;
}

/*For rcsnh004 field*/
.container-fluid form .row .col.col-129 { 
    flex-basis: 2%;
    margin-right: 3px;
    padding: 1px;
    background-color: black;
    color: white;
    font-family: OptumSansBold,Arial,sans-serif!important;
}

/*For rcsnh004 field*/
.container-fluid form .row .col.col-130 { 
    flex-basis: 2%;
    margin-right: 3em;
    padding: 1px;
    background-color: black;
    color: white;
    font-family: OptumSansBold,Arial,sans-serif!important;
}

/*For rcphn004 field*/
.container-fluid form .row .col.col-132 { 
    flex-basis: 2%;
    margin-right: 1em;
    margin-left: auto;
    padding: 1px;
    font-family: OptumSansBold,Arial,sans-serif!important;
}

/*For rcphn004 field*/
.container-fluid form .col.col-132 .display-val { 
    background-color: black;
    color: white;
    font-family: OptumSansBold,Arial,sans-serif!important;
}

/*For rcrx3009 field*/
.container-fluid form .row .col.col-126 { 
    flex-basis: 2%;
    margin-right: 3px;
    margin-left: auto;
    padding: 1px;
    background-color: black;
    color: white;
    font-family: OptumSansBold,Arial,sans-serif!important;
}

/* for pricing headers (RCSOR004) */
.container-fluid form .row .col.col-1812 { 
    font-size: large;
    flex-basis: 62.5em;
}
.container-fluid form .row .col.col-1813 { 
    padding-left: 4em;
    flex-basis: 16.5em;
}
.container-fluid form .row .col.col-1814 { 
    padding-left: 8em;
    flex-basis: 16.5em;
}
/* for RCSND007 screen */
.container-fluid form .row .col.col-1815 { 
    padding-left: 2.7em;
    flex-basis: 11em;
}

/* for RCSN5001 screen */
.container-fluid form .row .col.col-1816 { 
    padding-left: 2.3em;
    flex-basis: 9.5em
}
/* end of addClass section */

/* Optum button css */
#toolbar button{
    padding: 0 10px!important;
    margin-right: 4px;
}
.top-buttons {
    float: left;
}

#toolbar .function-keys .p-megamenu .p-megamenu-root-list>li>a span {
    color: #316BBE !important;
}

.export-button {
    color: #316BBE !important;
}

.export {
    border-color: #316BBE !important;
    border-radius: 25px !important;
    float: right;  
}

.export:hover {
    color: #ffffff;
    border-color: #316BBE !important;
    border-radius: 25px !important;
    float: right;
}

.p-megamenu .p-menuitem-link {
    padding: 0.857rem;
    border-width: 2px !important;
    color: #316BBE;
    background-color: #ffffff !important;
    font-family: OptumSansBold, Arial, sans-serif !important;
    border-radius: 20;
    transition: background-color 0.2s, box-shadow 0.2s;
    -webkit-user-select: none;
    user-select: none;
}

.p-megamenu .p-menuitem-link:hover {
    padding: 0.857rem;
    border-width: 2px !important;
    background-color: #a1a1a1 !important;
    font-family: OptumSansBold, Arial, sans-serif !important;
    border-radius: 20;
    transition: background-color 0.2s, box-shadow 0.2s;
    -webkit-user-select: none;
    user-select: none;
}

#toolbar .function-keys .p-megamenu .p-megamenu-root-list>li>a, #toolbar button {
    border-color: #316BBE !important;
    border-radius: 25px !important;
    border-width: 2px !important;
    background-color: #ffffff;
    font-family: OptumSansBold!important;
    padding-top: 2px!important;
    padding-bottom: 0!important;
    height: 26px;
}

.right-buttons #continue-button {
    margin: 0;
    background: var(--main-color-accent, #007ad9);
    color: #fff;
}

#toolbar .function-keys .p-megamenu .p-megamenu-root-list>li>a span:hover {
    color: #ffffff !important;
}


#toolbar .function-keys .p-megamenu .p-megamenu-root-list>li>a:hover, #toolbar button:hover {
    background-color: #316BBE !important;
    box-shadow: 0 2px #316BBE;
    color: #ffffff !important;
}

#toolbar .function-keys .p-megamenu .p-megamenu-root-list>li>a:active, #toolbar button:active {
    padding-top: 2px!important;
    padding-bottom: 0!important;
    box-shadow: none;
}

#toolbar > div.function-keys > div > ul > li.p-menuitem.p-menuitem-active div.fcnkey-label{
	text-transform:capitalize;
}

body .p-overlaypanel.calendar-overlay {
    border: none;
    left: unset !important;
}
/* end of Optum button css */

.remove-bottom-padding{
    margin-bottom: -15px;
}

.container-fluid form .row .col.col.col--3 {    
    flex-basis: 1%
}
.container-fluid form .row .col.col--30 {
    flex-basis: 4%;
}
.container-fluid form .row .col.col--40 {
    flex-basis: 3%;
}

.confirm-pharmacy-content {
     padding-top: 0px !important;
	 position: absolute;
     padding-left: 80px;
}

.confirm-pharmacy-content strong {
     padding: 0px !important;
	 font-family:sans-serif !important;
	 font-weight: normal !important;
}

.center-justify {
    text-align: center;
}

.radioStyle {
    color: #222;
    font-size: 12px !important;
    font-family: OptumSansBold,Arial,sans-serif!important;
    font-weight: bold;
}

.radioSameLine {
    color: #222!important;
    font-size: 14px!important;
    font-weight: bold;
    font-family: OptumSansBold,Arial,sans-serif!important;
    padding-right: 8px;
}

.multienv-label {
    display: flex;
    padding-left: 5px;
}

.radioSameLine:after {

}

.error-msg{
    float:left !important;
}
.f-grid .f-currency-input .p-inputtext, .f-grid .f-datetime-input .p-inputtext, .f-grid .f-numeric .p-inputtext {
    text-align: left;
}

.content-input .suffix {
    position: unset;
    font-size: 25px;
}

/* work arounds for reverse video indicators */
.container-fluid form .row .col.col-127 { 
    flex-basis: 2%;
    margin-right: 3em;
    margin-left: auto;
    padding: 1px;
    background-color: black;
    color: white;
    height: 28px;
    width: 25px;
    font-family: OptumSansBold,Arial,sans-serif!important;
}

.content-input .size-1 input.p-inputtext { flex-basis: 2em; }
.content-input .size-2 input.p-inputtext { flex-basis: 3em; }
.content-input .size-3 input.p-inputtext { flex-basis: 4em; }
.content-input .size-4 input.p-inputtext { flex-basis: 5em; }
.content-input .size-5 input.p-inputtext { flex-basis: 6em; }
.content-input .size-6 input.p-inputtext { flex-basis: 7em; }
.content-input .size-7 input.p-inputtext { flex-basis: 8em; }
.content-input .size-8 input.p-inputtext { flex-basis: 9em; }
.content-input .size-9 input.p-inputtext { flex-basis: 10em; }
.content-input .size-10 input.p-inputtext { flex-basis: 11em; }
.content-input .size-11 input.p-inputtext { flex-basis: 12em; }
.content-input .size-12 input.p-inputtext { flex-basis: 13em; }
.content-input .size-13 input.p-inputtext { flex-basis: 14em; }
.content-input .size-14 input.p-inputtext { flex-basis: 15em; }
.content-input .size-15 input.p-inputtext { flex-basis: 16em; }
.content-input .size-16 input.p-inputtext { flex-basis: 17em; }
.content-input .size-17 input.p-inputtext { flex-basis: 18em; }
.content-input .size-18 input.p-inputtext { flex-basis: 19em; }
.content-input .size-19 input.p-inputtext { flex-basis: 20em; }
.content-input .size-20 input.p-inputtext { flex-basis: 21em; }
.content-input .size-21 input.p-inputtext { flex-basis: 22em; }
.content-input .size-22 input.p-inputtext { flex-basis: 23em; }
.content-input .size-23 input.p-inputtext { flex-basis: 24em; }
.content-input .size-24 input.p-inputtext { flex-basis: 25em; }
.content-input .size-25 input.p-inputtext { flex-basis: 26em; }
.content-input .size-26 input.p-inputtext { flex-basis: 27em; }
.content-input .size-27 input.p-inputtext { flex-basis: 28em; }
.content-input .size-28 input.p-inputtext { flex-basis: 29em; }
.content-input .size-29 input.p-inputtext { flex-basis: 30em; }
.content-input .size-30 input.p-inputtext { flex-basis: 31em; }

/* custom classes */
.margin-negative{
    margin-top:-27px;
}

.margin-negative_1{
    margin-top:-31px;
}

.height-fix{
    height: 14px;
}

/* remove input field border show as text, using for number field FNumericInput */
.input-no-border  .content-input  .size-1 input.p-inputtext { border : none; }
.input-no-border  .content-input  .size-2 input.p-inputtext { border : none; }
.input-no-border  .content-input  .size-3 input.p-inputtext { border : none; }
.input-no-border  .content-input  .size-4 input.p-inputtext { border : none; }
.input-no-border  .content-input  .size-5 input.p-inputtext { border : none; }
.input-no-border  .content-input  .size-6 input.p-inputtext { border : none; }
.input-no-border  .content-input  .size-7 input.p-inputtext { border : none; }
.input-no-border  .content-input  .size-8 input.p-inputtext { border : none; }
.input-no-border  .content-input  .size-9 input.p-inputtext { border : none; }
.input-no-border  .content-input  .size-10 input.p-inputtext { border : none; }
.input-no-border  .content-input  .size-11 input.p-inputtext { border : none; }
.input-no-border  .content-input  .size-12 input.p-inputtext { border : none; }
.input-no-border  .content-input  .size-13 input.p-inputtext { border : none; }
.input-no-border  .content-input  .size-14 input.p-inputtext { border : none; }
.input-no-border  .content-input  .size-15 input.p-inputtext { border : none; }
.input-no-border  .content-input  .size-16 input.p-inputtext { border : none; }
.input-no-border  .content-input  .size-17 input.p-inputtext { border : none; }
.input-no-border  .content-input  .size-18 input.p-inputtext { border : none; }
.input-no-border  .content-input  .size-19 input.p-inputtext { border : none; }
.input-no-border  .content-input  .size-20 input.p-inputtext { border : none; }
.input-no-border  .content-input  .size-21 input.p-inputtext { border : none; }
.input-no-border  .content-input  .size-22 input.p-inputtext { border : none; }
.input-no-border  .content-input  .size-23 input.p-inputtext { border : none; }
.input-no-border  .content-input  .size-24 input.p-inputtext { border : none; }
.input-no-border  .content-input  .size-25 input.p-inputtext { border : none; }
.input-no-border  .content-input  .size-26 input.p-inputtext { border : none; }
.input-no-border  .content-input  .size-27 input.p-inputtext { border : none; }
.input-no-border  .content-input  .size-28 input.p-inputtext { border : none; }
.input-no-border  .content-input  .size-29 input.p-inputtext { border : none; }
.input-no-border  .content-input  .size-30 input.p-inputtext { border : none; } 

/* remove unnecessary space below the number input field  */
 .input-no-border .content-input {
    margin-bottom: 0px;
} 

td .resizable-container .p-dropdown .p-inputtext {
  text-align: left;
}
