/* @import url('https://fonts.googleapis.com/css?family=Sen:400,700,800&display=swap'); */

html,
body {
  margin: 0;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

body {
  background-color: var(--app-background, #fff);
  color: var(--app-text-color, #222);
  line-height: 1;
  font-size: 14px;
}

body,
body input,
body textarea,
body .p-component {
  /* font-family: var(--app-font-family, "Open Sans"), "Helvetica Neue", sans-serif;
  font-family: 'Roboto', sans-serif !important; */
  font-family: OptumSansRegular,Arial,sans-serif!important;
  font-size: 14px;
}

link-text { 
font-size: 12px; 
font-weight: normal; 
display: block; 
width: 150px; 
float: left; 
padding-top: 10px; 
padding-left: 0px;
} 

.content-input-link-text link-text { 
font-family: var(--app-font-family, "Open Sans"), "Helvetica Neue", sans-serif; 
font-family: 'Roboto', sans-serif !important;
padding-left: 0!important;
}

main {
  padding: 30px 2.5% 14px;
}

ol,
ul {
  list-style: none;
}

h1 {
  margin: 0 0 25px;
  font-size: 25px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'OptumSansBold', sans-serif;
}

body .app-root .p-dialog {
  border: none;
}

body .app-root .p-dialog .selectorTitle {
  margin: 0;
  text-align: center;
}

h1 span {
  font-weight: 600;
  color: var(--main-color-accent, #007ad9);
}

.page-content {
  padding-top: 35px;
}

p-table tr th {
  text-align: left;
}

.btn-group {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  margin: 12px 0;
}

.btn-group.left {
  justify-content: flex-start;
}

.btn-group.right {
  justify-content: flex-end;
}

body .layout-wrapper .p-sidebar {
  padding: 0;
  border: 0;
}


/* Prime styling overrides */
/* TODO : move this inside components if possible */
body .app-root .p-button {
  background-color: var(--main-color-accent, #007ad9);
  border-color: var(--main-color-accent, #007ad9);
}

body .app-root .p-button:enabled:hover,
body .app-root .p-button:enabled:focus {
  background-color: var(--main-color-dark, #005597);
  border-color: var(--main-color-dark, #005597);
}

body .app-root .p-button:enabled:active {
  background-color: var(--main-color-darker, #012e51);
  border-color: var(--main-color-darker, #012e51);
}

body .app-root .p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: var(--main-color-accent, #007ad9);
  background-color: var(--main-color-accent, #007ad9);
}

body .app-root .p-radiobutton .p-radiobutton-box.p-highlight.p-highlight,
body .app-root .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: var(--main-color-dark, #005597);
  background-color: var(--main-color-dark, #005597);
}

body .app-root .p-datepicker:not(.p-disabled) .p-datepicker-header .p-datepicker-prev:hover,
body .app-root .p-datepicker:not(.p-disabled) .p-datepicker-header .p-datepicker-next:hover {
  color: var(--main-color-accent, #007ad9);
}

body .app-root .p-datepicker table td>a.p-highlight {
  background-color: var(--main-color-accent, #007ad9);
}

body div.p-dropdown-panel {
  z-index: 99999 !important;
}

body .app-root .p-dropdown-panel {

  border: none;
}

body .app-root .p-dropdown-items {
  background: var(--input-background, #fff) !important;
  border: none;
}

body .app-root .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight) {
  color: var(--app-text-color, #222);
}

body .app-root .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  background-color: var(--main-color-light, #b8dbf6);
}

body .app-root .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
body .app-root .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group.p-highlight {
  background-color: var(--main-color-accent, #007ad9);
}

body p-toast .p-toast {
  z-index: 9999 !important;
}

.uppercase input {
  text-transform: uppercase;
}

.loader {
  width: 50px;
  height: 50px;
}

@keyframes ui-progress-spinner-color {

  100%,
  0% {
    stroke: var(--main-color-accent, #007ad9);
  }

  80% {
    stroke: #fff;
  }
}

.modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1100;
  display: flex;
  justify-content: center;
  align-items: center;
}

body app-header {
  background: var(--app-header, #fff);
}

body div.p-tooltip.p-component.p-tooltip-top {
  z-index: 99999 !important;
}

body .app-root .p-dialog .p-dialog-header {
  background-color: var(--app-header, #fff);
  color: var(--app-text-color, #fff);
  border: none;
}

body .app-root .p-dialog:not(.screen-confirm-dialog) .p-dialog-header {
  padding-top: 20px;
  padding-bottom: 10px;
}

body .app-root .p-dialog:not(.screen-confirm-dialog) .p-dialog-header > span {
  font-size: 25px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'OptumSansBold', sans-serif;
}

body .app-root .p-dialog.screen-confirm-dialog .p-dialog-header {
  padding: 20px;
  font-size: 18px;
}

body .app-root .p-dialog.screen-confirm-dialog .p-dialog-content {
  display: none; /* while we don't have any message to show for a screen confirm popup */
}

body .app-root .p-dialog .p-dialog-content .container-fluid {
  display: inline-block;
  width: 100%;
}

body .app-root .p-dialog .p-dialog-content {
  background-color: var(--modal-background, #fff);
  border: none;
}

body .app-root .p-dialog .p-dialog-content .container-fluid > .btn-group.left {
  float: left;
  width: calc(100% - 200px);
  margin: 0;
}

body .app-root .p-dialog .p-dialog-content .container-fluid > .btn-group.right {
  float: right;
  margin: 0;
}

body .app-root .p-dialog .p-dialog-content .container-fluid > .btn-group.right f-continue-button {
  position: relative;
  top: 6px;
}

body .app-root .p-dialog .p-dialog-footer {
  background-color: var(--app-header, #fff);
  color: var(--app-text-color, #fff);
  border: none;
}

body .app-root .p-menu.p-shadow {
  border: none;
}

body .app-root .p-menu .p-menuitem-link {
  background-color: var(--app-background, #fff);
}

body .app-root .p-menu .p-menuitem-link:hover {
  background-color: var(--main-color-pale, #4ba1e3);
}

body .app-root .p-menu .p-menuitem-link span {
  color: var(--app-text-color, #222) !important;
}

body .app-root .p-component.p-datepicker,
body .app-root .p-component.p-dropdown-panel {
  z-index: 9999 !important;
}

body .app-root .grid-error-tooltip .p-tooltip-arrow {
	display: none;
}

body .app-root .grid-error-tooltip {
	min-width: 250px;
}

body .app-root .grid-error-tooltip .p-tooltip-text {
	background: rgba(237, 7, 7, 0.8);
	line-height: 20px;
}

/* DATE-TIME INPUT CALENDAR OVERLAY */
body .app-root .calendar-overlay {
  border: none !important;
  background: none;
  z-index: 9999 !important;
}

body .app-root .calendar-overlay .p-datepicker {
	width: 100%;
}

body .app-root .calendar-overlay .p-datepicker table th,
body .app-root .calendar-overlay .p-datepicker table td > span {
  color: var(--app-text-color, #222);
}

body .app-root .calendar-overlay .p-datepicker table td > span {
  border-radius: 50%;
}

body .app-root .calendar-overlay .p-datepicker table td > span:not(.p-highlight):not(.p-disabled):hover {
  background: rgba(200, 200, 200, 0.2) !important;
}

body .app-root .calendar-overlay .p-datepicker table td > span:not(.p-highlight):not(.p-disabled):focus,
body .app-root .calendar-overlay .p-datepicker table td > span:not(.p-highlight):not(.p-disabled):active {
  background: rgba(200, 200, 200, 0.35) !important;
}

body .app-root .calendar-overlay .p-datepicker table td > .p-highlight {
  color: #fff;
}

body .app-root .calendar-overlay .p-datepicker .p-datepicker-header {
  background: var(--modal-background, #fff);
  color: var(--app-text-color, #222);
}

body .app-root.calendar-overlay .p-datepicker .p-timepicker span {
  color: var(--app-text-color, #222);
}

body .app-root .calendar-overlay .p-overlaypanel-content {
  padding: 0;
}

body .app-root .calendar-overlay.p-overlaypanel:after,
body .app-root .calendar-overlay.p-overlaypanel:before {
  display: none !important;
}

body .app-root .calendar-overlay .p-datepicker.p-datepicker-inline {
  background: var(--modal-background, #fff);
  border: none;
  display: inline-block;
}

body .app-root .p-timepicker > div {
  margin-left: 0;
}

body .app-root .p-datepicker-buttonbar .p-button.hidden {
  display: none; 
}

body .app-root .p-datepicker-buttonbar button.p-button-secondary {
 background-color: rgba(200, 200, 200, 0.3) !important;
 color: var(--app-text-color, #222) !important;
 border: none !important;
}

body .app-root .p-datepicker .p-datepicker-buttonbar {
  border-top: 1px solid rgba(200,200,200,0.2);
}

body .app-root .p-datepicker table td.p-datepicker-today > a,
body .app-root .p-datepicker:not(.p-disabled) table td a:not(.p-highlight):not(.p-highlight):hover {
  background-color: rgba(200, 200, 200, 0.2);
}

/* HEADER MENU */
body .header-menu.p-overlaypanel {
  margin: 0;
  top: 15px !important;
  border: none;
}

body .header-menu.p-overlaypanel::after,
body .header-menu.p-overlaypanel::before {
  display: none;
}

body .header-menu.p-overlaypanel .p-overlaypanel-content {
  padding: 0;
  background: var(--modal-background, #fff);
}

body .header-menu.p-overlaypanel .p-megamenu-panel,
body .header-menu.p-overlaypanel .p-overlaypanel-content .p-megamenu {
  background: var(--modal-background, #fff);
  border: none;
}

body .header-menu.p-overlaypanel .p-overlaypanel-content .p-megamenu {
  border: none;
}

body .header-menu.p-overlaypanel .p-overlaypanel-content .p-megamenu a {
  border-radius: 3px;
  outline: none !important;
  background: transparent;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

body .header-menu.p-overlaypanel .p-overlaypanel-content .p-megamenu a:focus,
body .header-menu.p-overlaypanel .p-overlaypanel-content .p-megamenu a:hover,
body .header-menu.p-overlaypanel .p-overlaypanel-content .p-megamenu a:active {
  background: rgba(200, 200, 200, 0.3) !important;
}

body .header-menu.p-overlaypanel .p-overlaypanel-content .p-megamenu a span {
  color: var(--app-text-color, #222) !important;
  opacity: 0.7;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

body .header-menu.p-overlaypanel .p-overlaypanel-content .p-megamenu a:hover span,
body .header-menu.p-overlaypanel .p-overlaypanel-content .p-megamenu a:focus span,
body .header-menu.p-overlaypanel .p-overlaypanel-content .p-megamenu a:active span {
  opacity: 1;
}

body .header-menu.p-overlaypanel .p-overlaypanel-content .p-megamenu .p-megamenu-submenu-header {
  background: rgba(200, 200, 200, 0.15);
  color: var(--app-text-color, #222);
}

body .header-menu.p-overlaypanel .p-overlaypanel-content .p-megamenu .p-megamenu-submenu-header:empty {
  display: none;
}


.indicators {
  display: flex;
}

.indicator {
  margin: 0 10px;
  min-width: 30px;
}

.highlight-note {
  background-color: var(--main-color-accent);
  color: var(--button-action);
  padding: 5px 10px;
}

.note-mrgn{
  margin-top: 8px;
}
