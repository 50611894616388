.card.p-card {
    background-color: var(--app-background, #fff) !important;
    width: 360px !important;
    height: max-content !important;
    position: absolute !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    margin: auto !important;
    box-shadow: none !important;
    color: var(--app-text-color, #fff) !important;
}

.card .p-card-content {
    text-align: center;
}

.card.p-card .p-card-footer .p-button{
    margin-left: 34% !important;
}