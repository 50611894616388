.display {
	table-layout:auto;
	width:20%;
	min-width: 85%;
	font-size: 12px;
}

.display td {
	white-space: nowrap;
	padding: 2px;
	padding: 5px;
	border: 1px solid #D0D0CE;
}

strong {
	color: #316BBE
}

.text {
	font-size: 12px;
}

.deaCode {
	padding-left:50px;
}

.dates {
	font-size: 12px;
}

.date-header {
	color: #316BBE
}

.expand-row {
	cursor: default; 
}

.expand-row:hover {
	cursor: pointer; 
}

.expand-row:active {
	cursor: default; 
}