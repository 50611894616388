/* ====================================
//  TOOLBAR
// ================================= */
#toolbar {
  height: 48px;
  background: var(--app-toolbar, #f4f4f4);
  padding: 0 12px;
  float: right !important;
}
    
    
#toolbar .top-function-keys {
      height: 48px;
      background: var(--app-toolbar, #f4f4f4);
      padding: 0 12px;
      float: left !important;
}

#toolbar:not(.popup-keys) {
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  z-index: 998;
  padding: 0 45px;
}

#toolbar:not(.popup-keys).bottom {
  top: unset;
  bottom: 0;
  -webkit-box-shadow: 0 0 20px 0 rgba(0,0,0,0.2);
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.2);
}

#toolbar.popup-keys {
  border-radius: 4px;
}

#toolbar .right-buttons,
#toolbar .function-keys {
  display: inline-flex;
  align-items: center;
  height: 37px;
}

#toolbar .function-keys {
  width: calc(100% - 200px);
  padding-top: 5px!important;
  /*overflow-x: auto;*/
}

#toolbar .function-keys .p-megamenu {
  /*position: absolute;*/
  left: 45px;
  top: 0;
}

#toolbar .function-keys .p-megamenu  {
  display: flex;
  padding: 0;
  height: 48px;
  background: none;
  border: none;
}

#toolbar .function-keys .p-megamenu .p-megamenu-root-list > li > a {
  background: var(--button-fkey, #f4f4f4);
  border: 1px solid var(--button-fkey-border, #ddd);
  height: 26px;
  padding: 0 10px;
  font-size: 12px;
  text-transform: capitalize;
  margin-right: 20px;
  border-radius: 3px!important;
  letter-spacing: 1px;
  outline: none;
  box-shadow: none;
}

#toolbar .function-keys .p-megamenu ul.p-megamenu-submenu li a span,
#toolbar .function-keys .p-megamenu .p-megamenu-root-list > li > a span {
  color: var(--button-fkey-text, #222);
}

#toolbar .function-keys .p-megamenu ul.p-megamenu-submenu li a:hover,
#toolbar .function-keys .p-megamenu .p-megamenu-root-list > li > a:hover {
  background: var(--button-fkey-hover, #c8c8c8);
}

#toolbar .function-keys .p-megamenu ul.p-megamenu-submenu li a:focus,
#toolbar .function-keys .p-megamenu ul.p-megamenu-submenu li a:active,
#toolbar .function-keys .p-megamenu .p-megamenu-root-list > li > a:focus,
#toolbar .function-keys .p-megamenu .p-megamenu-root-list > li > a:active {
  background: var(--button-fkey-focus, #959595);
}

#toolbar .function-keys .p-megamenu ul ul .p-megamenu-submenu-header {
  display: none;
}

#toolbar > div.function-keys > div > ul > li > a > span.p-menuitem-text{
  display: none;
}

#toolbar > div.function-keys > div > ul > li.p-menuitem.p-menuitem-active div.fcnkey-label
{
  text-align: left; 
  width:49%;  
  display: inline-block
}

#toolbar > div.function-keys > div > ul > li.p-menuitem.p-menuitem-active div.fcnkey-key
{
  text-align: right; 
  width:50%;  
  display: inline-block
}

#toolbar .function-keys .p-megamenu .p-megamenu-col-12 {
  padding: 0;
}

#toolbar .function-keys .p-megamenu .p-megamenu-root-list>.p-menuitem-active>.p-megamenu-panel {
  border: none;
  margin-top: 11px;
}

#toolbar .function-keys .p-megamenu ul.p-megamenu-submenu li a {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 14px 10px;
  background: var(--button-fkey, #f4f4f4);
}

#toolbar .function-keys .p-megamenu ul li a>span span {
  vertical-align: middle;
}

#toolbar .function-keys .p-megamenu ul li a>img.custom-image {
  margin-right: 0.5rem;
  vertical-align: middle;
  width: 18px;
  height: 18px;
}


#toolbar .right-buttons {
  float: right;
}

#toolbar button {
  color: var(--app-text-color, #222);
  background: rgba(200, 200, 200, 0.25);
  box-shadow: none;
  font-size: 12px;
  text-transform: capitalize;
  letter-spacing: 1px;
  margin-right: 12px;
  min-width: initial;
  width: initial;
  height: initial;
  font-weight: normal;
  padding: 5px 10px;
  border-radius: 3px !important;
}

.right-buttons #continue-button {
  margin: 0;
  background: var(--main-color-accent, #007ad9);
  color: #fff;
}

/* ====================================
//  INPUTS & LABELS
// ================================= */
form .row f-label,
:local() f-label .content-input label {
  width: 100%;
  display: inline-block;
}

:local(f-label).align-center .content-input label { text-align: center; }
:local(f-label).align-right .content-input label { text-align: right; }

.col.right-label {
  display: flex;
  align-items: center;
  flex-basis: unset!important;
  margin: 0 1.25% 0 0!important;
  height: 34px;
}

.col.right-label .content-input {
  margin: 0;
}

.col.right-label .content-input label {
  font-size: 12px;
}

/* ====================================
//  POPUP SCREENS
// ================================= */
body.app-root .p-dialog {
  border: none;
}

body.app-root .p-dialog .p-dialog-footer,
body.app-root .p-dialog .p-dialog-content,
body.app-root .p-dialog .p-dialog-header,
body.app-root .p-dialog .p-dialog-titlebar {
  background-color: var(--modal-background, #2a2a2a);
  border: none;
  color: var(--app-text-color, #222) !important;
}

body.app-root .p-dialog.confirm-dialog
{
  z-index: 10001 !important;
    font-size: 18px;
}

body.app-root .p-multiselect-panel
{
  z-index: 25001 !important;
}

body.app-root .p-dialog.confirm-dialog .p-dialog-header {
  display: none;
}

body.app-root .p-dialog.confirm-dialog .p-dialog-titlebar {
  display: none;
}

body.app-root .p-dialog.confirm-dialog .p-dialog-footer {
  text-align: left;
  padding: 0 20px 15px;
}

body.app-root .p-dialog.confirm-dialog .p-dialog-content {
  padding: 20px;
  font-weight: 700;
  font-size: 1rem;
}

body.app-root .p-dialog .p-dialog-header span,
body.app-root .p-dialog .p-dialog-titlebar span {
  display: inline-block;
  margin-top: 8px;
  font-size: 25px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: OptumSansBold, sans-serif;
  color:  #316BBE !important;
}

/* ====================================
//  RESPONSIVE
// ================================= */
@media (max-width: 780px) {
  h1 {
    font-size: 22px;
    margin: 0 0 15px;
  }

  #toolbar:not(.popup-keys) {
    padding: 0 18px;
  }

  .container-fluid form .row .col:not(.legacy) {
    flex-basis: 30%;
  }
}

@media (max-width: 600px) {
  .container-fluid form .row .col:not(.legacy) {
    flex-basis: 47%;
  }
}

@media (max-width: 480px) {
  .container-fluid form .row .col:not(.legacy) {
    flex-basis: 97%;
  }
}

body.app-root .p-multiselect-panel {  
  z-index: 25001 !important;
}
